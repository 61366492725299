import "./LegendsSlider.sass";

import legend_1_high_voltage from "../assets/legends-slider/legends/1_high_voltage.png";
import legend_2_fairy from "../assets/legends-slider/legends/2_fairy.png";
import legend_3_a_badass from "../assets/legends-slider/legends/3_a._badass.png";
import legend_4_bat_biter from "../assets/legends-slider/legends/4_bat_biter.png";
import legend_5_grunge_god from "../assets/legends-slider/legends/5_grunge_god.png";
import legend_6_rastafari from "../assets/legends-slider/legends/6_rastafari.png";
import legend_7_piano_prodigy from "../assets/legends-slider/legends/7_piano_prodigy.png";
import legend_8_romeo_blue from "../assets/legends-slider/legends/8_romeo_blue.png";
import legend_9_the_trumpet from "../assets/legends-slider/legends/9_the_trumpet.png";
import legend_10_cm_sunshine from "../assets/legends-slider/legends/10_c.m._sunshine.png";
import legend_11_dave_jay from "../assets/legends-slider/legends/11_dave_jay.png";
import legend_12_a_dreamer from "../assets/legends-slider/legends/12_a._dreamer.png";
import legend_13_may_b_lonely from "../assets/legends-slider/legends/13_may_b._lonely.png";
import legend_14_proud_mary from "../assets/legends-slider/legends/14_proud_mary.png";
import legend_15_the_king from "../assets/legends-slider/legends/15_the_king.png";
import legend_16_justin_holdon from "../assets/legends-slider/legends/16_justin_holdon.png";
import legend_17_the_queen from "../assets/legends-slider/legends/17_the_queen.png";
import legend_18_masked_singer from "../assets/legends-slider/legends/18_masked_singer.png";
import legend_19 from "../assets/legends-slider/legends/19_thug_life.png";
import legend_20 from "../assets/legends-slider/legends/20_dangerous_flower.png";
import legend_21 from "../assets/legends-slider/legends/21_avocado.png";
import legend_22 from "../assets/legends-slider/legends/22_rastafari.png";
import legend_23 from "../assets/legends-slider/legends/23_b.o._roses.png";
import legend_24 from "../assets/legends-slider/legends/24_gangsta.png";
import legend_25 from "../assets/legends-slider/legends/25_DMG.png";
import legend_26 from "../assets/legends-slider/legends/26_lady_supreme.png";
import legend_27 from "../assets/legends-slider/legends/27_swiss_dj.png";
import legend_28 from "../assets/legends-slider/legends/28_ic_fire.png";
import legend_29 from "../assets/legends-slider/legends/29_rocket_man.png";
import legend_30 from "../assets/legends-slider/legends/30_wabbit.png";
import legend_31 from "../assets/legends-slider/legends/31_just_a_girl.png";
import legend_32 from "../assets/legends-slider/legends/32_p.o._destiny.png";
import legend_33 from "../assets/legends-slider/legends/33_wah_wah.png";
import legend_34 from "../assets/legends-slider/legends/34_moonwalker.png";
import legend_35 from "../assets/legends-slider/legends/35_the_avanger.png";
import legend_36 from "../assets/legends-slider/legends/36_p._magnet.png";
import legend_37 from "../assets/legends-slider/legends/37_black_painter.png";
import legend_38 from "../assets/legends-slider/legends/38_the_glue.png";
import legend_39 from "../assets/legends-slider/legends/39_p._rain.png";
import legend_40 from "../assets/legends-slider/legends/40_ng_guy.png";
import legend_41 from "../assets/legends-slider/legends/41_reeree.png";
import legend_42 from "../assets/legends-slider/legends/42_bang_arang.png";
import legend_43 from "../assets/legends-slider/legends/44_double_g.png";
import legend_44 from "../assets/legends-slider/legends/43_the_hat.png";
import legend_45 from "../assets/legends-slider/legends/45_walk_his_way.png";
import legend_46 from "../assets/legends-slider/legends/46_engel.png";
import legend_47 from "../assets/legends-slider/legends/47_you_doh.png";


import {useState} from "react";


interface Legend {
    icon: string;
    name: string;
}

const legends: Legend[] = [
    {
        icon: legend_1_high_voltage,
        name: "High Voltage"
    },
    {
        icon: legend_2_fairy,
        name: "Fairy"
    },
    {
        icon: legend_3_a_badass,
        name: "A. Badass"
    },
    {
        icon: legend_4_bat_biter,
        name: "Bat Biter"
    },
    {
        icon: legend_5_grunge_god,
        name: "Grunge God"
    },
    {
        icon: legend_6_rastafari,
        name: "Rastafari"
    },
    {
        icon: legend_7_piano_prodigy,
        name: "Piano Prodigy"
    },
    {
        icon: legend_8_romeo_blue,
        name: "Romeo Blue"
    },
    {
        icon: legend_9_the_trumpet,
        name: "The Trumpet"
    },
    {
        icon: legend_10_cm_sunshine,
        name: "C.M. Sunshine"
    },
    {
        icon: legend_11_dave_jay,
        name: "Dave Jay"
    },
    {
        icon: legend_12_a_dreamer,
        name: "A Dreamer"
    },
    {
        icon: legend_13_may_b_lonely,
        name: "May B. Lonely"
    },
    {
        icon: legend_14_proud_mary,
        name: "Proud Mary"
    },
    {
        icon: legend_15_the_king,
        name: "The King"
    },
    {
        icon: legend_16_justin_holdon,
        name: "Justin Holdon"
    },
    {
        icon: legend_17_the_queen,
        name: "The Queen"
    },
    {
        icon: legend_18_masked_singer,
        name: "Masked Singer"
    },
    {
        icon: legend_19,
        name: "Thug Life"
    },
    {
        icon: legend_20,
        name: "Dangerous Flower"
    },
    {
        icon: legend_21,
        name: "Avocado"
    },
    {
        icon: legend_22,
        name: "Rastafari"
    },
    {
        icon: legend_23,
        name: "B.O. Roses"
    },
    {
        icon: legend_24,
        name: "Gangsta"
    },
    {
        icon: legend_25,
        name: "DMG"
    },
    {
        icon: legend_26,
        name: "Lady Supreme"
    },
    {
        icon: legend_27,
        name: "Swiss DJ"
    },
    {
        icon: legend_28,
        name: "I.C. Fire"
    },
    {
        icon: legend_29,
        name: "Rocket Man"
    },
    {
        icon: legend_30,
        name: "Wabbit"
    },
    {
        icon: legend_31,
        name: "Just a girl"
    },
    {
        icon: legend_32,
        name: "P.O. Destiny"
    },
    {
        icon: legend_33,
        name: "Wah Wah"
    },
    {
        icon: legend_34,
        name: "Moonwalker"
    },
    {
        icon: legend_35,
        name: "The Avenger"
    },
    {
        icon: legend_36,
        name: "P. Magnet"
    },
    {
        icon: legend_37,
        name: "Black Painter"
    },
    {
        icon: legend_38,
        name: "The Glue"
    },
    {
        icon: legend_39,
        name: "P. Rain"
    },
    {
        icon: legend_40,
        name: "NG Guy"
    },
    {
        icon: legend_41,
        name: "ReeRee"
    },
    {
        icon: legend_42,
        name: "The Hat"
    },
    {
        icon: legend_43,
        name: "Double G"
    },
    {
        icon: legend_44,
        name: "The Hat"
    },
    {
        icon: legend_45,
        name: "Walk his way"
    },
    {
        icon: legend_46,
        name: "Engel"
    },
    {
        icon: legend_47,
        name: "You Doh"
    }
];

export function LegendsSlider({isMobile = true}: { isMobile: boolean }) {
    const [active, setActive] = useState<number>(6);

    const width = isMobile?35:15;

    const left = () => {
        setActive(Math.max(0, active-1));
    };
    const right = () => {
        setActive(Math.min(legends.length-1, active+1));
    };

    let offset = -active*width + 50 - width/2;

    return <div className="section-legends-slider">
        <div className="section-header"/>
        <div className="slider">
            <div className="slider-button slider-button-left" onClick={left} />
            <div className="slider-button slider-button-right" onClick={right} />
            <div className="slider-items" style={{
                marginLeft: offset+'vw'
            }}>
                {legends.map((legend, i) =>
                    <div className={"slider-item " + (active==i?"active":"inactive")} key={i}>
                        <div className="image">
                            <div className="spotlight">
                                <img src={legend.icon} />
                            </div>
                        </div>
                        <div className="label-container">
                            <div className="label">
                                {legend.name}
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </div>
    </div>
}
