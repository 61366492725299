import headline from "../assets/howto/headline-minting-howto.svg";
import "./HowTo.sass";
import {useState} from "react";

const howtos = [
    {
        header: "Preamble: What’s an NFT?",
        content: `
        The acronym NFT stands for “Non-fungible Token.” A non-fungible token is a form of digital
asset where the authenticity can be verified through blockchain technology and smart
contracts. Most of the non-fungible tokens fit in the categories of digital collectibles, music,
artwork, and in-game items. An in-depth guide explaining NFTs can be found <a href='href="https://opensea.io/blog/guides/non-fungible-tokens/' target='_blank'>here</a>.
`
    },
    {
        header: "Download and Install crypto wallet",
        content: `
        Get an Ethereum compatible Crypto Wallet and install it to your Computer. We recommend
<a href="https://metamask.io/download" target="_blank">MetaMask</a>, it is free and can be downloaded <a href="https://metamask.io/download" target="_blank">here</a>.
After downloading and installing the Crypto Wallet, a unique wallet address has been
assigned to you. This is similar to an E-Mail address. Except you are not sending mails but
crypto currencies to that address.
`
    },
    {
        header: "Fund your wallet",
        content: "Send some Ethereum to your wallet. Some wallets allow you to buy ETH directly from within\n" +
            "the wallet with your credit card. But maybe you already have some ETH on Coinbase or\n" +
            "another wallet. A Uniquehorn NFT will cost you 0.0666 ETH + gas fee (blockchain\n" +
            "calculation fee) so we recommend to transfer like 0.1 ETH to your wallet.\n"
    },
    {
        header: "Buy a Rocking Uniquehorn (“Minting”)",
        content: "Minting an NFT is the process of 'buying' and NFT. The process of writing your NFT to the blockchain where it\n" +
            "will be stored forever and also associated with your wallet, identifying you as the owner. Got\n" +
            "to our <a href='https://mint.rockinguniquehorns.com/therockinguniquehorns' target='_blank'>Minting page</a> and select the number of Uniquehorns you want to mint (max. 10 per\n" +
            "Transaction for fairness reason to avoid one person buying all NFTs with a single\n" +
            "transaction).<br/><br/>" +
            "Follow the steps in MetaMask.\n"
    },
    {
        header: "Enjoy your Uniquehorn!",
        content: `It might take 5-15 minutes until your purchase (“mint”) is written to the blockchain. After you
get the confirmation on our minting page, the NFT Uniquehorn belongs to you!
<br/><br/>
You can trade it on Secondary Marketplaces like <a href='https://opensea.io/collection/the-rocking-uniquehorns' target='_blank'>OpenSea</a> where other people can send you
bids on your Uniquehorn. Or you can just keep it in your wallet, the so called “floor price”
(aka how much is my NFT worth?) can also be checked on OpenSea.`
    }
];


export function HowTo() {
    const [openItems, setOpenItems] = useState<number[]>([]);

    const triggerOpen = (item: number) => {
        const ind = openItems.indexOf(item);
        if (ind < 0) {
            setOpenItems([...openItems, item]);
        } else {
            openItems.splice(ind, 1);
            setOpenItems([...openItems]);
        }
    };
    const isOpen = (item: number) => {
        return openItems.indexOf(item) >= 0;
    };

    return <div className="section-how-to">
        <div className="section-background-header" />
        <div className="section-content-wrapper">
            <div className="section-content">
                <div className="section-header">
                    <img src={headline} />
                </div>
                <div className="top-comment">
                    This is a quick step guide helping you to get your first NFT, in this case an NFT of “The
                    Rocking Uniquehorns” collection. There’s a total supply of 9’990 Uniquehorn NFTs, this
                    number is fixed and there will never be any more Rocking Uniquehorns made. So get your
                    Rocking Uniquehorn now… it’s simple.
                </div>
                <div className="howto-list">
                    {howtos.map((item, i) =>
                        <div className={"element " + (isOpen(i)?'active':'')} key={item.header}>
                            <div className="element-header" onClick={()=>triggerOpen(i)}>
                                <div className="number">
                                    <div>{i+1}</div>
                                </div>
                                <div className="header">
                                    {item.header}
                                </div>
                                <div className="separator">
                                    <div />
                                </div>
                                <div className="toggle">
                                    <div />
                                </div>
                            </div>
                            <div className="content" dangerouslySetInnerHTML={{__html: item.content}}/>
                        </div>)
                    }
                </div>
            </div>
        </div>
    </div>
}
