import "./About.sass";
import {AudioVisualArt} from "./AudioVisualArt";


export function About({isMobile = true}: { isMobile: boolean }) {
    return <div className="section-about">
        <div className="top-bg"/>
        <div className="about-content">
            <div className="section-header"/>
            <div className="text">
                <div className="text-header">The Rocking Uniquehorns (TRUs)</div>
                <p>Music to the Metaverse!<br/>
                    We are the number one community for music experiences in the metaverse and run the TRU band room in Decentraland. <br/><br/>
                    Mint your TRU NFT now and join our band!
                </p>
            </div>
            <AudioVisualArt isMobile={isMobile}/>
        </div>
    </div>
}
