import opensea from "../assets/Main_Landing_Page/Website_Assets/icon-opensea.svg";
import discord from "../assets/Main_Landing_Page/Website_Assets/icon-discord.svg";
import twitter from "../assets/Main_Landing_Page/Website_Assets/icon-twitter.svg";
import "./Footer.sass";

import {config} from "../config";
import decentral from "../assets/decentraland-mana-logo-small.png";

export function Footer() {
    return <div className="footer-section">
        <div className="unicorn" />
        <div className="discord-text">
            Join the fun and <br/>
            become part of our <br/>
            community on Discord

            <div className="discord-button">
                <a href={config.discordLink}>
                    <div>DISCORD</div>
                    <div className="arrow"/>
                </a>
            </div>
        </div>
        <div className="icons">
            <ul>
                <li>
                    <a href={config.openseaLink}>
                        <img src={opensea} />
                    </a>
                </li>
                <li>
                    <a href={config.decentrallangLink}>
                        <img src={decentral} />
                    </a>
                </li>
                <li>
                    <a href={config.discordLink}>
                        <img src={discord} />
                    </a>
                </li>
                <li>
                    <a href={config.twitterLink}>
                        <img src={twitter} />
                    </a>
                </li>
            </ul>
        </div>

        <div className="press">
            <a href="https://drive.google.com/drive/u/0/folders/1hdYuIOdTZ81NkuvQziZWha8G_d9ZlnnP" target="_blank">Press & Mediakit</a>
        </div>
    </div>
}
