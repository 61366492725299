import {useState} from "react";

import "./Calculator.sass";

import moneystack1 from "../assets/calculator/calculator-moneystack1.png";
import moneystack2 from "../assets/calculator/calculator-moneystack2.png";
import moneystack3 from "../assets/calculator/calculator-moneystack3.png";
import moneystack4 from "../assets/calculator/calculator-moneystack4.png";
import moneystack5 from "../assets/calculator/calculator-moneystack5.png";
import moneystack6 from "../assets/calculator/calculator-moneystack6.png";
import moneystack7 from "../assets/calculator/calculator-moneystack7.png";

import actors_1_1 from "../assets/calculator/actors/1/rk1.png";
import actors_1_2 from "../assets/calculator/actors/1/rk2.png";
import actors_1_3 from "../assets/calculator/actors/1/rk3.png";
import actors_1_4 from "../assets/calculator/actors/1/rk4.png";
import actors_1_5 from "../assets/calculator/actors/1/rk5.png";
import actors_1_6 from "../assets/calculator/actors/1/rk6.png";
import actors_1_7 from "../assets/calculator/actors/1/rk7.png";
import actors_1_8 from "../assets/calculator/actors/1/rk8.png";
import actors_1_9 from "../assets/calculator/actors/1/rk9.png";
import actors_1_10 from "../assets/calculator/actors/1/rk10.png";

import actors_2_1 from "../assets/calculator/actors/2/yo1.png";
import actors_2_2 from "../assets/calculator/actors/2/yo2.png";
import actors_2_3 from "../assets/calculator/actors/2/yo3.png";
import actors_2_4 from "../assets/calculator/actors/2/yo4.png";
import actors_2_5 from "../assets/calculator/actors/2/yo5.png";
import actors_2_6 from "../assets/calculator/actors/2/yo6.png";
import actors_2_7 from "../assets/calculator/actors/2/yo7.png";
import actors_2_8 from "../assets/calculator/actors/2/yo8.png";
import actors_2_9 from "../assets/calculator/actors/2/yo9.png";
import actors_2_10 from "../assets/calculator/actors/2/yo10.png";

import actors_3_1 from "../assets/calculator/actors/3/co1.png";
import actors_3_2 from "../assets/calculator/actors/3/co2.png";
import actors_3_3 from "../assets/calculator/actors/3/co3.png";
import actors_3_4 from "../assets/calculator/actors/3/co4.png";
import actors_3_5 from "../assets/calculator/actors/3/co5.png";
import actors_3_6 from "../assets/calculator/actors/3/co6.png";
import actors_3_7 from "../assets/calculator/actors/3/co7.png";
import actors_3_8 from "../assets/calculator/actors/3/co8.png";
import actors_3_9 from "../assets/calculator/actors/3/co9.png";
import actors_3_10 from "../assets/calculator/actors/3/co10.png";

import actors_4_1 from "../assets/calculator/actors/4/bb1.png";
import actors_4_2 from "../assets/calculator/actors/4/bb2.png";
import actors_4_3 from "../assets/calculator/actors/4/bb3.png";
import actors_4_4 from "../assets/calculator/actors/4/bb4.png";
import actors_4_5 from "../assets/calculator/actors/4/bb5.png";
import actors_4_6 from "../assets/calculator/actors/4/bb6.png";
import actors_4_7 from "../assets/calculator/actors/4/bb7.png";
import actors_4_8 from "../assets/calculator/actors/4/bb8.png";
import actors_4_9 from "../assets/calculator/actors/4/bb9.png";
import actors_4_10 from "../assets/calculator/actors/4/bb10.png";

import actors_5_1 from "../assets/calculator/actors/5/mozart1.png";
import actors_5_2 from "../assets/calculator/actors/5/mozart2.png";
import actors_5_3 from "../assets/calculator/actors/5/mozart3.png";
import actors_5_4 from "../assets/calculator/actors/5/mozart4.png";
import actors_5_5 from "../assets/calculator/actors/5/mozart5.png";
import actors_5_6 from "../assets/calculator/actors/5/mozart6.png";
import actors_5_7 from "../assets/calculator/actors/5/mozart7.png";
import actors_5_8 from "../assets/calculator/actors/5/mozart8.png";
import actors_5_9 from "../assets/calculator/actors/5/mozart9.png";
import actors_5_10 from "../assets/calculator/actors/5/mozart10.png";

import actors_6_1 from "../assets/calculator/actors/6/kl-01.png";
import actors_6_2 from "../assets/calculator/actors/6/kl-02.png";
import actors_6_3 from "../assets/calculator/actors/6/kl-03.png";
import actors_6_4 from "../assets/calculator/actors/6/kl-04.png";
import actors_6_5 from "../assets/calculator/actors/6/kl-05.png";
import actors_6_6 from "../assets/calculator/actors/6/kl-06.png";
import actors_6_7 from "../assets/calculator/actors/6/kl-07.png";
import actors_6_8 from "../assets/calculator/actors/6/kl-08.png";
import actors_6_9 from "../assets/calculator/actors/6/kl-09.png";
import actors_6_10 from "../assets/calculator/actors/6/kl-10.png";

import actors_7_1 from "../assets/calculator/actors/7/aj1.png";
import actors_7_2 from "../assets/calculator/actors/7/aj2.png";
import actors_7_3 from "../assets/calculator/actors/7/aj3.png";
import actors_7_4 from "../assets/calculator/actors/7/aj4.png";
import actors_7_5 from "../assets/calculator/actors/7/aj5.png";
import actors_7_6 from "../assets/calculator/actors/7/aj6.png";
import actors_7_7 from "../assets/calculator/actors/7/aj7.png";
import actors_7_8 from "../assets/calculator/actors/7/aj8.png";
import actors_7_9 from "../assets/calculator/actors/7/aj9.png";
import actors_7_10 from "../assets/calculator/actors/7/aj10.png";

import actors_8_1 from "../assets/calculator/actors/8/a1.png";
import actors_8_2 from "../assets/calculator/actors/8/a2.png";
import actors_8_3 from "../assets/calculator/actors/8/a3.png";
import actors_8_4 from "../assets/calculator/actors/8/a4.png";
import actors_8_5 from "../assets/calculator/actors/8/a5.png";
import actors_8_6 from "../assets/calculator/actors/8/a6.png";
import actors_8_7 from "../assets/calculator/actors/8/a7.png";
import actors_8_8 from "../assets/calculator/actors/8/a8.png";
import actors_8_9 from "../assets/calculator/actors/8/a9.png";
import actors_8_10 from "../assets/calculator/actors/8/a10.png";

import actors_9_1 from "../assets/calculator/actors/9/a1.png";
import actors_9_2 from "../assets/calculator/actors/9/a2.png";
import actors_9_3 from "../assets/calculator/actors/9/a3.png";
import actors_9_4 from "../assets/calculator/actors/9/a4.png";
import actors_9_5 from "../assets/calculator/actors/9/a5.png";
import actors_9_6 from "../assets/calculator/actors/9/a6.png";
import actors_9_7 from "../assets/calculator/actors/9/a7.png";
import actors_9_8 from "../assets/calculator/actors/9/a8.png";
import actors_9_9 from "../assets/calculator/actors/9/a9.png";
import actors_9_10 from "../assets/calculator/actors/9/a10.png";

import actors_10_1 from "../assets/calculator/actors/10/a1.png";
import actors_10_2 from "../assets/calculator/actors/10/a2.png";
import actors_10_3 from "../assets/calculator/actors/10/a3.png";
import actors_10_4 from "../assets/calculator/actors/10/a4.png";
import actors_10_5 from "../assets/calculator/actors/10/a5.png";
import actors_10_6 from "../assets/calculator/actors/10/a6.png";
import actors_10_7 from "../assets/calculator/actors/10/a7.png";
import actors_10_8 from "../assets/calculator/actors/10/a8.png";
import actors_10_9 from "../assets/calculator/actors/10/a9.png";
import actors_10_10 from "../assets/calculator/actors/10/a10.png";

import actors_11_1 from "../assets/calculator/actors/11/a1.png";
import actors_11_2 from "../assets/calculator/actors/11/a2.png";
import actors_11_3 from "../assets/calculator/actors/11/a3.png";
import actors_11_4 from "../assets/calculator/actors/11/a4.png";
import actors_11_5 from "../assets/calculator/actors/11/a5.png";
import actors_11_6 from "../assets/calculator/actors/11/a6.png";
import actors_11_7 from "../assets/calculator/actors/11/a7.png";
import actors_11_8 from "../assets/calculator/actors/11/a8.png";
import actors_11_9 from "../assets/calculator/actors/11/a9.png";
import actors_11_10 from "../assets/calculator/actors/11/a10.png";

import actors_12_1 from "../assets/calculator/actors/12/a1.png";
import actors_12_2 from "../assets/calculator/actors/12/a2.png";
import actors_12_3 from "../assets/calculator/actors/12/a3.png";
import actors_12_4 from "../assets/calculator/actors/12/a4.png";
import actors_12_5 from "../assets/calculator/actors/12/a5.png";
import actors_12_6 from "../assets/calculator/actors/12/a6.png";
import actors_12_7 from "../assets/calculator/actors/12/a7.png";
import actors_12_8 from "../assets/calculator/actors/12/a8.png";
import actors_12_9 from "../assets/calculator/actors/12/a9.png";
import actors_12_10 from "../assets/calculator/actors/12/a10.png";

import actors_13_1 from "../assets/calculator/actors/13/a1.png";
import actors_13_2 from "../assets/calculator/actors/13/a2.png";
import actors_13_3 from "../assets/calculator/actors/13/a3.png";
import actors_13_4 from "../assets/calculator/actors/13/a4.png";
import actors_13_5 from "../assets/calculator/actors/13/a5.png";
import actors_13_6 from "../assets/calculator/actors/13/a6.png";
import actors_13_7 from "../assets/calculator/actors/13/a7.png";
import actors_13_8 from "../assets/calculator/actors/13/a8.png";
import actors_13_9 from "../assets/calculator/actors/13/a9.png";
import actors_13_10 from "../assets/calculator/actors/13/a10.png";

import actors_14_1 from "../assets/calculator/actors/14/a1.png";
import actors_14_2 from "../assets/calculator/actors/14/a2.png";
import actors_14_3 from "../assets/calculator/actors/14/a3.png";
import actors_14_4 from "../assets/calculator/actors/14/a4.png";
import actors_14_5 from "../assets/calculator/actors/14/a5.png";
import actors_14_6 from "../assets/calculator/actors/14/a6.png";
import actors_14_7 from "../assets/calculator/actors/14/a7.png";
import actors_14_8 from "../assets/calculator/actors/14/a8.png";
import actors_14_9 from "../assets/calculator/actors/14/a9.png";
import actors_14_10 from "../assets/calculator/actors/14/a10.png";

import actors_15_1 from "../assets/calculator/actors/15/a1.png";
import actors_15_2 from "../assets/calculator/actors/15/a2.png";
import actors_15_3 from "../assets/calculator/actors/15/a3.png";
import actors_15_4 from "../assets/calculator/actors/15/a4.png";
import actors_15_5 from "../assets/calculator/actors/15/a5.png";
import actors_15_6 from "../assets/calculator/actors/15/a6.png";
import actors_15_7 from "../assets/calculator/actors/15/a7.png";
import actors_15_8 from "../assets/calculator/actors/15/a8.png";
import actors_15_9 from "../assets/calculator/actors/15/a9.png";
import actors_15_10 from "../assets/calculator/actors/15/a10.png";

import actors_16_1 from "../assets/calculator/actors/16/a1.png";
import actors_16_2 from "../assets/calculator/actors/16/a2.png";
import actors_16_3 from "../assets/calculator/actors/16/a3.png";
import actors_16_4 from "../assets/calculator/actors/16/a4.png";
import actors_16_5 from "../assets/calculator/actors/16/a5.png";
import actors_16_6 from "../assets/calculator/actors/16/a6.png";
import actors_16_7 from "../assets/calculator/actors/16/a7.png";
import actors_16_8 from "../assets/calculator/actors/16/a8.png";
import actors_16_9 from "../assets/calculator/actors/16/a9.png";
import actors_16_10 from "../assets/calculator/actors/16/a10.png";


import actors_17_1 from "../assets/calculator/actors/17/a1.png";
import actors_18_1 from "../assets/calculator/actors/18/a1.png";
import actors_19_1 from "../assets/calculator/actors/19/a1.png";
import actors_20_1 from "../assets/calculator/actors/20/a1.png";
import actors_21_1 from "../assets/calculator/actors/21/a1.png";
import actors_22_1 from "../assets/calculator/actors/22/a1.png";
import actors_23_1 from "../assets/calculator/actors/23/a1.png";
import actors_24_1 from "../assets/calculator/actors/24/a1.png";
import actors_25_1 from "../assets/calculator/actors/25/a1.png";
import actors_26_1 from "../assets/calculator/actors/26/a1.png";
import actors_27_1 from "../assets/calculator/actors/27/a1.png";
import actors_28_1 from "../assets/calculator/actors/28/a1.png";
import actors_29_1 from "../assets/calculator/actors/29/a1.png";
import actors_30_1 from "../assets/calculator/actors/30/a1.png";
import actors_31_1 from "../assets/calculator/actors/31/a1.png";
import actors_32_1 from "../assets/calculator/actors/32/a1.png";

import actor_disabled from "../assets/calculator/calculator-legend-disabled-02.svg";

import ReactSlider from "react-slider";

interface ActorsSet {
    number: number;
    actors?: string[];
    sounds?: string[];
}

type Actors = ActorsSet[];

const sounds = (actor: number) =>
    Array.from(Array(10)).map((_, i) =>
        `/audio/sounds/${actor}/${i+1}.wav`
    );

const soundsLegend = (actor: number) =>
    Array.from(Array(10)).map((_, i) =>
        `/audio/sounds/${actor}/${actor}.wav`
    );

const actors: Actors = [
    {number: 1, sounds: sounds(1), actors: [
        actors_1_1,
        actors_1_2,
        actors_1_3,
        actors_1_4,
        actors_1_5,
        actors_1_6,
        actors_1_7,
        actors_1_8,
        actors_1_9,
        actors_1_10
    ]},
    {number: 2, sounds: sounds(2), actors: [
        actors_2_1,
        actors_2_2,
        actors_2_3,
        actors_2_4,
        actors_2_5,
        actors_2_6,
        actors_2_7,
        actors_2_8,
        actors_2_9,
        actors_2_10
    ]},
    {number: 3, sounds: sounds(3), actors: [
        actors_3_1,
        actors_3_2,
        actors_3_3,
        actors_3_4,
        actors_3_5,
        actors_3_6,
        actors_3_7,
        actors_3_8,
        actors_3_9,
        actors_3_10
    ]},
    {number: 4, sounds: sounds(4), actors: [
        actors_4_1,
        actors_4_2,
        actors_4_3,
        actors_4_4,
        actors_4_5,
        actors_4_6,
        actors_4_7,
        actors_4_8,
        actors_4_9,
        actors_4_10
    ]},
    {number: 5, sounds: sounds(5), actors: [
        actors_5_1,
        actors_5_2,
        actors_5_3,
        actors_5_4,
        actors_5_5,
        actors_5_6,
        actors_5_7,
        actors_5_8,
        actors_5_9,
        actors_5_10
    ]},
    {number: 6, sounds: sounds(6), actors: [
        actors_6_1,
        actors_6_2,
        actors_6_3,
        actors_6_4,
        actors_6_5,
        actors_6_6,
        actors_6_7,
        actors_6_8,
        actors_6_9,
        actors_6_10
    ]},
    {number: 7, sounds: sounds(7), actors: [
        actors_7_1,
        actors_7_2,
        actors_7_3,
        actors_7_4,
        actors_7_5,
        actors_7_6,
        actors_7_7,
        actors_7_8,
        actors_7_9,
        actors_7_10
    ]},
    {number: 8, sounds: sounds(8), actors: [
        actors_8_1,
        actors_8_2,
        actors_8_3,
        actors_8_4,
        actors_8_5,
        actors_8_6,
        actors_8_7,
        actors_8_8,
        actors_8_9,
        actors_8_10
    ]},
    {number: 9, sounds: sounds(9), actors: [
        actors_9_1,
        actors_9_2,
        actors_9_3,
        actors_9_4,
        actors_9_5,
        actors_9_6,
        actors_9_7,
        actors_9_8,
        actors_9_9,
        actors_9_10
    ]},
    {number: 10, sounds: sounds(10), actors: [
        actors_10_1,
        actors_10_2,
        actors_10_3,
        actors_10_4,
        actors_10_5,
        actors_10_6,
        actors_10_7,
        actors_10_8,
        actors_10_9,
        actors_10_10
    ]},
    {number: 11, sounds: sounds(11), actors: [
        actors_11_1,
        actors_11_2,
        actors_11_3,
        actors_11_4,
        actors_11_5,
        actors_11_6,
        actors_11_7,
        actors_11_8,
        actors_11_9,
        actors_11_10
    ]},
    {number: 12, sounds: sounds(12), actors: [
        actors_12_1,
        actors_12_2,
        actors_12_3,
        actors_12_4,
        actors_12_5,
        actors_12_6,
        actors_12_7,
        actors_12_8,
        actors_12_9,
        actors_12_10
    ]},
    {number: 13, sounds: sounds(13), actors: [
        actors_13_1,
        actors_13_2,
        actors_13_3,
        actors_13_4,
        actors_13_5,
        actors_13_6,
        actors_13_7,
        actors_13_8,
        actors_13_9,
        actors_13_10
    ]},
    {number: 14, sounds: sounds(14), actors: [
        actors_14_1,
        actors_14_2,
        actors_14_3,
        actors_14_4,
        actors_14_5,
        actors_14_6,
        actors_14_7,
        actors_14_8,
        actors_14_9,
        actors_14_10
    ]},
    {number: 15, sounds: sounds(15), actors: [
        actors_15_1,
        actors_15_2,
        actors_15_3,
        actors_15_4,
        actors_15_5,
        actors_15_6,
        actors_15_7,
        actors_15_8,
        actors_15_9,
        actors_15_10
    ]},
    {number: 16, sounds: sounds(16), actors: [
        actors_16_1,
        actors_16_2,
        actors_16_3,
        actors_16_4,
        actors_16_5,
        actors_16_6,
        actors_16_7,
        actors_16_8,
        actors_16_9,
        actors_16_10
    ]},
    {number: 17, sounds: sounds(17), actors: [
        actors_17_1
    ]},
    {number: 18, sounds: sounds(18), actors: [
        actors_18_1
    ]},
    {number: 19, sounds: sounds(19), actors: [
        actors_19_1
    ]},
    {number: 20, sounds: sounds(20), actors: [
        actors_20_1
    ]},
    {number: 21, sounds: sounds(21), actors: [
        actors_21_1
    ]},
    {number: 22, sounds: sounds(22), actors: [
        actors_22_1
    ]},
    {number: 23, sounds: sounds(23), actors: [
        actors_23_1
    ]},
    {number: 24, sounds: sounds(24), actors: [
        actors_24_1
    ]},
    {number: 25, sounds: sounds(25), actors: [
        actors_25_1
    ]},
    {number: 26, sounds: sounds(26), actors: [
        actors_26_1
    ]},
    {number: 27, sounds: sounds(27), actors: [
        actors_27_1
    ]},
    {number: 28, sounds: sounds(28), actors: [
        actors_28_1
    ]},
    {number: 29, sounds: sounds(29), actors: [
        actors_29_1
    ]},
    {number: 30, sounds: sounds(30), actors: [
        actors_30_1
    ]},
    {number: 31, sounds: sounds(31), actors: [
        actors_31_1
    ]},
    {number: 32, sounds: sounds(32), actors: [
        actors_32_1
    ]},
];
const alen = actors.length;
actors.push(
    ...Array.from(Array(45-alen+1)).map((_, i)=>({
        number: i+alen+1,
    }))
);

const moneys = [
    moneystack1,
    moneystack2,
    moneystack3,
    moneystack4,
    moneystack5,
    moneystack6,
    moneystack7
];

export function Calculator ({isMobile = true}: { isMobile: boolean }) {
    const [selectedActorsSet, setSelectedActorsSet] = useState<number>(1);
    const [selectedActor, setSelectedActor] = useState<number>(0);
    const [imageSliderOffset, setImageSliderOffset] = useState<number>(0);

    const isMobileInRow = {
        0: 5,
        1: 3
    };

    const onArrowClick = (dir: number) => {
        const inRow = isMobileInRow[Number(isMobile) as keyof typeof isMobileInRow];
        let newVal = imageSliderOffset + dir*inRow;
        newVal = Math.min(actors.length-1-inRow, newVal);
        newVal = Math.max(0, newVal);
        setImageSliderOffset(newVal);
    };

    const onSetSelected = (number: number) => {
        setSelectedActor(0);
        setSelectedActorsSet(number);
    };

    const onSliderChange = (val: number) => {
        setSelectedActor(val);
    };

    const selectedSet = actors.find(s => s.number == selectedActorsSet);
    const imageSliderOffsetMargin = (imageSliderOffset * (isMobile?24:7) * -1)+'vw';
    const selectedActorImage = selectedSet!.actors![selectedActor];

    let selectedMoneyImage = moneystack1;
    if (selectedActor == 1) selectedMoneyImage = moneystack2;
    if (selectedActor == 2 || selectedActor == 3) selectedMoneyImage = moneystack3;
    if (selectedActor == 4) selectedMoneyImage = moneystack4;
    if (selectedActor == 5 || selectedActor == 6) selectedMoneyImage = moneystack5;
    if (selectedActor == 7 || selectedActor == 8) selectedMoneyImage = moneystack6;
    if (selectedActor >= 9) selectedMoneyImage = moneystack7;

    const soundcheck = () => {
        const sounds = selectedSet!.sounds!;
        let audio = sounds[selectedActor];
        if (!audio) {
            audio = sounds[sounds.length - 1];
        }
        console.log(audio);
        new Audio(audio).play();
    };

    return <div className="calculator-container">
        <div className="col calculator">
            <h2>TRU Legends - the core of our NFT collection</h2>
            <h4>45 original TRU Legends</h4>
            <div className="images-slider">
                <div className="arrow left" onClick={()=>onArrowClick(-1)}></div>
                <div className="arrow right" onClick={()=>onArrowClick(1)}></div>
                <div className="images-container">
                    <div className="images-set"
                        style={{marginLeft: imageSliderOffsetMargin}}
                    >
                        {actors.map((actorsSet, i)=>{
                            const selected = (actorsSet.number == selectedActorsSet)?"active":"";
                            const actorImage = actorsSet.actors?actorsSet.actors[actorsSet.actors.length-1]:actor_disabled;
                            return <div className={"image "+selected}
                                        key={i}
                                        onClick={()=>{
                                            if (!actorsSet.actors)
                                                return;
                                            onSetSelected(actorsSet.number)
                                        }}
                            >
                                <div className="image-wrapper">
                                    <div className="number">#{actorsSet.number}</div>
                                    <img src={actorImage} />
                                </div>
                            </div>
                        })}
                    </div>
                </div>
            </div>
            <h4>Uniquehorn inspiration level</h4>
            <div className="slider-wrapper">
                <ReactSlider
                    className="slider"
                    min={0}
                    max={Object.keys(selectedSet!.actors!).length-1}
                    thumbClassName={"slider-thumb"}
                    value={selectedActor}
                    onChange={val=>onSliderChange(val)}
                />
            </div>
        </div>
        <div className="col money">
            <div className="actor-image">
                <img src={selectedActorImage} />
            </div>
            <div className="money-image">
                <img src={selectedMoneyImage} />
            </div>
            <button className="sound-check"
                onClick={soundcheck}
            />
        </div>
    </div>
}
