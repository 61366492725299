import "./Metaverse.sass"

export function Metaverse() {
    return <>
            <div className="section-metaverse">
            <div className="blocks">
                <div className="main block">
                    <div className="main-text">
                        <h2>Music to the Metaverse!</h2>
                        <h3>We're building the community, partnerships, infrastructure and tools to enable new music experiences in the metaverse and beyond.</h3>
                        <div className="text">

                        </div>
                    </div>
                    <div className="main-image" />
                </div>
                <div className="sub">
                    <div className="block">
                        <h2>We help artists by...</h2>
                        <ul>
                            <li>
                                <span className="text">💰 buying their NFTs</span>
                            </li>
                            <li>
                                <span className="text">💪 supporting & funding their projects</span>
                            </li>
                            <li>
                                <span className="text">🚀 launching them into the Metaverse</span>
                            </li>
                            <li>
                                <span className="text">💸 creating their NFTs</span>
                            </li>
                            <li>
                                <span className="text">💥 increasing their visibility</span>
                            </li>
                        </ul>
                    </div>
                    <div className="block">
                        <h2>What's in it for me?</h2>
                        <ul>
                            <li>
                                <span className="text">👪 Community Fund voting rights</span>
                            </li>
                            <li>
                                <span className="text">📣 Free +gas NFT (Instruments & next gen)</span>
                            </li>
                            <li>
                                <span className="text">🎶 Access to TRU powered Decentraland events</span>
                            </li>
                            <li>
                                <span className="text">🎸 Exclusive Collabs</span>
                            </li>
                            <li>
                                <span className="text">👕 Merch</span>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </>
}
