import "./Rarity.sass";
import {Calculator} from "./Calculator";
import {Worldtour} from "./Worldtour";


export function Rarity({isMobile = true}: { isMobile: boolean }) {
    return <>
        <div className="section-rarity">
            <div className="top-bg"/>
            <div className="about-content">
                <div className="section-header"/>
                <div className="text">
                        <p>The Rocking Uniquehorns (<b>TRU</b>) are randomly generated by combining over 300 regular, legendary and ultra rare visual as well as over 50 acoustic traits, giving each one its very own unique style and sound.</p>
                        <p>The collection was started by hand drawing our unique 1/1 characters known as the <b>TRU Legends</b>. They are our tribute to some of the greatest music artists this world has ever heard.</p>
                        <p>The TRU Legends are shuffled into the rest of the collection, so everyone has the same chance of minting one of them.</p>
                        <br/>

                    </div>
                <Calculator isMobile={isMobile}/>
            </div>
        </div>
    </>
}
