import "./Page.sass";
import {Header} from "./sections/Header";
import {useEffect, useState} from "react";
import {Menu} from "./Page";
import {Footer} from "./sections/Footer";
import "./HallOfFamePage.sass";
import {HallOfFame} from "./sections/HallOfFame";


export function HallOfFamePage() {
    const [isMobile, setIsMobile] = useState<boolean>(true);

    const setVh = () => {
        let vh = window.innerHeight * 0.01;
        document.documentElement.style.setProperty('--vh', `${vh}px`);
    };

    useEffect(()=>{
        if (isMobile && document.body.offsetWidth>=480) {
            setIsMobile(false);
            return;
        }
        setVh();
        window.addEventListener("resize", ()=>setVh());
    }, []);

    const navigateTo = (menu: Menu) => {
        window.location.href = "/?section="+menu;
    };

    return <div className="sections">
        <div className="section auto header-hall-of-fame-section">
            <Header onNavigation={menu => navigateTo(menu)}
                    activeNavigation={undefined}
                    isMobile={isMobile}
                    onlyMenu={true}
            />
        </div>
        <div className="section auto">
            <HallOfFame isHomepage={false}/>
        </div>
        <div className="section auto">
            <Footer/>
        </div>
    </div>;
}

