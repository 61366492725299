import React from 'react';
import ReactDOM from 'react-dom';
import {Page} from "./Page";
import {BrowserRouter, Route, Routes} from "react-router-dom";
import {HallOfFamePage} from "./HallOfFamePage";

ReactDOM.render(
    <React.StrictMode>
        <BrowserRouter>
            <PageApp/>
        </BrowserRouter>
    </React.StrictMode>,
    document.getElementById('root')
);

function PageApp() {
    return <Routes>
        <Route path="/" element={<Page/>}/>
        <Route path="/hall-of-fame" element={<HallOfFamePage/>} />
    </Routes>
}
