import "./Bandroom.sass";
import event1img from "./../assets/events/20211217_ashley.png";
import event2img from "./../assets/events/20211218_sassy.png";
import event3img from "./../assets/events/20211220_dj.png";
import {RefObject, useEffect, useState} from "react";
import playbutton from "./../assets/bandroom-recent-gigs-play.svg";
import {Menu} from "../Page";

const API_URL = "https://api.rockinguniquehorns.com/public/dcl";

interface Gig {
    id: string;
    name: string;
    description: string;
    moreInfoLink: string;
    date: string;
    artist: Artist;
    text?: string;
    images: Image
}

interface Artist {
    name: string;
    images: Image;
    mints: number;
    description?: string;
    links?: Link[]
}

interface Link {
    text: string;
    url: string;
}
interface Image {
    full: string;
    thumbnail: string;
}

export function Bandroom() {
    const recentGigs = [
        {
            video: "/video/yahzarah.mp4",
            poster: "/video/video1.png",
            header: "Band Room Gig<br/>YAHZARAH"
        },
        {
            video: "/video/tutroti.mp4",
            poster: "/video/video2.png",
            header: "Bufalo - Metaverse Tour"
        },
        {
            video: "/video/decentraland.mp4",
            poster: "/video/video3.png",
            header: "Our very first Gig<br/>Ash Orphan"
        },
    ];
    const [refs, _] = useState([]);
    const [upcomingGigs, setUpcomingGigs] = useState<Gig[]>([]);
    const [playing, setPlaying] = useState<number[]>([]);


    const loadGigs = async (type: String = "upcoming") => {
        const gigs : Gig[] = await fetch(API_URL + "/config.php?max=3&type=" + type).then(res => res.json());
        setUpcomingGigs(gigs);
    };

    const playVideo = (i: number) => {
        setPlaying([i, ...playing]);
        const video = refs[i];
        if (!video) return;
        // @ts-ignore
        video.play();
    };

    const truncate = (input: string, length: number = 600) => {
        var text = input;
        if(input.length > length) {
            text = input.substring(0, length - 3) + "...";
        }
        return text;
    }

    const isPlaying = (i: number) => {
        return playing.includes(i);
    };

    useEffect(()=>{
        loadGigs();
    }, []);

    return <div className="section-bandroom">
        <div className="section-header"/>
        <div className="top-text">
            <div>
                <a href="https://party.rockinguniquehorns.com" target="_blank">Visit our band room in Decentraland!</a> We are hosting gigs with our artists in Decentraland frequently, as a Rocking Uniquehorn
                holder you will be able to join our exclusive gigs and get in contact with our artists!
            </div>
        </div>
        <div className="events">
            {upcomingGigs.map((gig, i)=><div className="event" key={i}>
                <div className="image">
                    <img src={gig.images.thumbnail} />
                </div>
                <div className="date">
                    {new Date(gig.date).toLocaleDateString()} {new Date(gig.date).toLocaleTimeString()}
                </div>
                <div className="header">
                    {gig.name}
                </div>
                <div className="text">
                    {gig.description ? truncate(gig.description) : ''}
                </div>
                {gig.moreInfoLink&&<div className="button">
                    <a target="_blank" href={gig.moreInfoLink}>
                        <div>EVENT DETAILS</div>
                        <div className="arrow" />
                    </a>
                </div>}
            </div>)}

        </div>
        <div className="recent-gigs-header"/>
        <div className="recent-gigs">
            {recentGigs.map((el, i)=><div className="event" key={i}>
                <div className="event-video">
                    <video preload ="none" poster={el.poster} ref={ref=>{// @ts-ignore
                        refs[i]=ref;return true;}}
                           controls={isPlaying(i)}
                    >
                        <source src={el.video} type="video/mp4" />
                    </video>
                    {!isPlaying(i)&&<div className={"play-button"} onClick={()=>playVideo(i)}>
                        <img src={playbutton} />
                    </div>}
                </div>
                <div className="footer">
                    <div className="header" dangerouslySetInnerHTML={{__html: el.header}}/>
                </div>
            </div>)}
        </div>
    </div>
}
