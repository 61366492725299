import "./HallOfFame.sass";
import tru from "../assets/halloffame/artists/0_TRU/boro-punk-blue-bg.png";
import ash_orphan from "../assets/halloffame/artists/1_Ash Orphan/Ash Orphan.png";
import c4ru5 from "../assets/halloffame/artists/1C4RU5/1C4RU5.png";
import chase_the_river from "../assets/halloffame/artists/2_Chase The River/Chase The River.png";
import king_mizo from "../assets/halloffame/artists/3_King Mizo/King Mizo.png";
import jack_frost from "../assets/halloffame/artists/4_Jack Frost/Jack Frost.png";
import dj_tropix from "../assets/halloffame/artists/5_DJ Tropix/DJ Tropix.png";
import griffter from "../assets/halloffame/artists/6_Griffter/Griffter.png";
import domino from "../assets/halloffame/artists/7_Domino/Domino.png";
import dyl from "../assets/halloffame/artists/8_Dyl/Dyl.png";
import thomas_penninger from "../assets/halloffame/artists/9_Thomas Penninger/Thomas Penninger.png";
import ocho_aok from "../assets/halloffame/artists/10_Ocho Aok/Ocho Aok.png";
import yah_zarah from "../assets/halloffame/artists/11_YahZarah/YahZarah.png";
import zanib from "../assets/halloffame/artists/12_zanib/zanib.png";
import bufalo from "../assets/halloffame/artists/13_bufalo/bufalo.jpeg";
import mrswe from "../assets/halloffame/artists/14_mrswe/mrswe.jpeg";
import serena_elis from "../assets/halloffame/artists/15_Serena Elis/serenaelis.png";
import ambie_nft from "../assets/halloffame/artists/16_ambie_nft/ambie.png";
import munir_griffin from "../assets/halloffame/artists/17_Munir Griffin/munir.png";
import pat_dimitri from "../assets/halloffame/artists/18_Pat Dimitri/pat_dimitri.png";
import nifty_sax from "../assets/halloffame/artists/19_Nifty Sax/nifty_sax_profile.png";
import kyle_lenout from "../assets/halloffame/artists/20_Kyle Lenout/kyle.png";
import leo_pastel from "../assets/halloffame/artists/21_Leo Pastel/leo.png";
import knarley_noso from "../assets/halloffame/artists/22_Knarley Noso/knarley.png";
import ashley_strongarm from "../assets/halloffame/artists/23_Ashley Strongarm/ashley.png";

import soundcloud from "../assets/halloffame/hall-of-fame-icon-soundcloud.svg";
import audius from "../assets/halloffame/hall-of-fame-icon-audius.svg";
import website from "../assets/halloffame/hall-of-fame-icon-website.svg";
import profile from "../assets/halloffame/musician.svg";

type Buttons = {
    [key in keyof typeof buttons]?: string;
};

const buttons = {
    soundcloud: soundcloud,
    audius: audius,
    website: website,
    profile: profile
};

interface Artist {
    name: string;
    photo: string;
    text: string;
    buttons?: Buttons;
}

const artists: Artist[] = [
    {
        name: "Mr Swe",
        photo: mrswe,
        text: 'You are next here.',
        buttons: {
            website: "https://mint.rockinguniquehorns.com/mrswe",
            profile: "https://twitter.com/MrSweMusic1"
        }
    },
    {
        name: "Ash Orphan",
        photo: ash_orphan,
        text: "Ash Orphan",
        buttons: {
            website: "https://mint.rockinguniquehorns.com/ashorphan",
        }
    },
    {
        name: "1C4RU5",
        photo: c4ru5,
        text: "Kindness always matters.",
        buttons: {
            website: "https://mint.rockinguniquehorns.com/1c4ru5",
            profile: "https://twitter.com/boredape6716"
        }
    },
    {
        name: "Chase The River",
        photo: chase_the_river,
        text: "Chase The River",
        buttons: {
            website: "https://mint.rockinguniquehorns.com/chasetheriver",
        }
    },
    {
        name: "King Mizo",
        photo: king_mizo,
        text: "King Mizo",
        buttons: {
            website: "https://mint.rockinguniquehorns.com/kingmizo",
        }
    },
    {
        name: "Jack Frost",
        photo: jack_frost,
        text: "Dream bigger.",
        buttons: {
            website: "https://mint.rockinguniquehorns.com/jackfrost",
        }
    },
    {
        name: "DJ Tropix",
        photo: dj_tropix,
        text: "DJ Tropix",
        buttons: {
            website: "https://mint.rockinguniquehorns.com/djtropix",
        }
    },
    {
        name: "Griffter",
        photo: griffter,
        text: "Griffter",
        buttons: {
            website: "https://mint.rockinguniquehorns.com/griffter",
        }
    },
    {
        name: "Domino",
        photo: domino,
        text: "lfg!",
        buttons: {
            website: "https://mint.rockinguniquehorns.com/domino",
            profile: "https://twitter.com/dominosmusic"
        }
    },
    {
        name: "Dyl",
        photo: dyl,
        text: "Dyl",
        buttons: {
            website: "https://mint.rockinguniquehorns.com/dyl",
        }
    },
    {
        name: "Thomas Penninger",
        photo: thomas_penninger,
        text: "Thomas Penninger",
        buttons: {
            website: "https://mint.rockinguniquehorns.com/thomaspenninger",
        }
    },
    {
        name: "OCHO AOK",
        photo: ocho_aok,
        text: "OCHO AOK",
        buttons: {
            website: "https://mint.rockinguniquehorns.com/ochoaok",
        }
    },
    {
        name: "YahZarah",
        photo: yah_zarah,
        text: "Be self encouraged...follow your inner visions.",
        buttons: {
            website: "https://mint.rockinguniquehorns.com/yahzarah",
        }
    },
    {
        name: "Zanib",
        photo: zanib,
        text: "Zanib",
        buttons: {
            website: "https://mint.rockinguniquehorns.com/zanib",
        }
    },
    {
        name: "The Rocking Uniquehorns",
        photo: tru,
        text: "The Rocking Uniquehorns",
        buttons: {
            website: "https://mint.rockinguniquehorns.com/therockinguniquehorns",
        }
    },
    {
        name: "Bufalo",
        photo: bufalo,
        text: "Bufalo",
        buttons: {
            website: "https://mint.rockinguniquehorns.com/bufalo",
        }
    },
    {
        name: "Serena Elis",
        photo: serena_elis,
        text: "Serena Elis",
        buttons: {
            website: "https://mint.rockinguniquehorns.com/serenaelis",
        }
    },
    {
        name: "Munir Griffin",
        photo: munir_griffin,
        text: "Munir Griffin",
        buttons: {
            website: "https://mint.rockinguniquehorns.com/munirgriffin",
        }
    },
    {
        name: "AmbieNFT",
        photo: ambie_nft,
        text: "AmbieNFT",
        buttons: {
            website: "https://mint.rockinguniquehorns.com/ambienft",
        }
    },
    {
        name: "Pat Dimitri",
        photo: pat_dimitri,
        text: "Pat Dimitri",
        buttons: {
            website: "https://mint.rockinguniquehorns.com/patdimitri",
        }
    },
    {
        name: "Nifty Sax",
        photo: nifty_sax,
        text: "Nifty Sax",
        buttons: {
            website: "https://mint.rockinguniquehorns.com/niftysax",
        }
    },
    {
        name: "Kyle Lenout",
        photo: kyle_lenout,
        text: "Kyle Lenout",
        buttons: {
            website: "https://mint.rockinguniquehorns.com/kylenout",
        }
    },
    {
        name: "Leo Pastel",
        photo: leo_pastel,
        text: "Leo Pastel",
        buttons: {
            website: "https://mint.rockinguniquehorns.com/leopastel",
        }
    },
    {
        name: "Knarley Noso",
        photo: knarley_noso,
        text: "Knarley Noso",
        buttons: {
            website: "https://mint.rockinguniquehorns.com/knarleynoso",
        }
    },
    {
        name: "Ashley Strongarm",
        photo: ashley_strongarm,
        text: "Ashley Strongarm",
        buttons: {
            website: "https://mint.rockinguniquehorns.com/ashleystrongarm",
        }
    }
];


export function HallOfFame({isHomepage = true}: { isHomepage: boolean }) {
    const homepageArtists = ["Ashley Strongarm","Domino","Nifty Sax"];
    let displayArtists = artists;
    if (isHomepage) {
        displayArtists = displayArtists.filter(a=>homepageArtists.includes(a.name));
    }

    return <div className={"section-hall-of-fame "+(isHomepage?"":"page")}>
        <div className="section-header" />
        <div className="hall-of-fame-section-content">
            <div className="artists">
                {displayArtists.map((artist, i) => <div className="artist">
                    <div className="photo">
                        <img src={artist.photo} />
                    </div>
                    <div className="name">
                        {artist.name}
                    </div>
                    <div className="buttons">
                        {Object.keys(buttons)
                            .filter(b=>artist.buttons&&artist.buttons[b as keyof Buttons])
                            .map(b=><a href={artist.buttons![b as keyof Buttons]}
                                       target="_blank"
                                       className={b}
                                       key={b}>
                                <img src={buttons[b as keyof typeof buttons]} />
                            </a>)
                        }
                    </div>
                </div>)}
            </div>
            {isHomepage&&<div className="bottom-button">
                <a href="/hall-of-fame">
                    SHOW ALL MUSICIANS <span className="arrow" />
                </a>
            </div>}
        </div>
    </div>
}
