import "./Faq.sass";
import {useState} from "react";

interface FaqItem {
    header: string;
    content: string;
}

const FaqItems: FaqItem[] = [
    {
        header: "What is 'The Rocking Uniquehorns'?",
        content: "We're an original collection of 9'990 randomly assembled Rocking Uniquehorns hanging out on the ETH blockchain as ERC721 Tokens (NFT)."
    },
    {
        header: "WTF Unicorns?!?",
        content: "WTF, Human?!? Now go to your room and think about how that makes you feel! 🎙️💧"
    },
    {
        header: "What is a TRU?",
        content: "TRU is just short for <b>T</b>he <b>R</b>ocking <b>U</b>niquehorns"
    },
    {
        header: "What is a TRU Legend?",
        content: "One of our 1/1 characters. Randomly distributed in the collection so that everyone has the same chance of minting one. The rest of the TRUs inherit some of their legendary traits."
    },
    {
        header: "How much?",
        content: "It’ll cost you 0.0666 ETH (+gas) to mint 1 Rocking Uniquehorn."
    },
    {
        header: "How many TRUs will be on sale and will you hold any back?",
        content: "There are 9'990 TRUs. We will hold back 190 TRUs for the team pfps, collaborations, promotions and giveaways."
    },
    {
        header: "Where's your contract? ",
        content: "You can find our contract <a href='https://etherscan.io/address/0xfaa5018134192eed6ffc13a862724bfafa05a8f8' target='_blank'>here</a>"
    },
    {
        header: "Where can I trade the Rocking Uniquehorns?",
        content: "You can trade the on <a href='https://opensea.io/collection/the-rocking-uniquehorns' target='_blank'>Opensea</a>."
    },
    {
        header: "Where will the data be hosted?",
        content: "We'll move everything over to decentralized storage (IPFS) after we're sold out and our after launch collaborations are done."
    },
    {
        header: "What can I do with my Rocking Uniquehorn?",
        content: "It will be your way into the tHE ΞnTRUpY. It will also give you a voice in our band. So you can have a say on which artists we support, how we spend the community funds and what our first musical creation will sound like. As a holder you will also participate in any community events. <br/>" +
            "Other than that you can do anything with it. Like, whatever you want. Since they are unicorns (for some weird reason ^^) you can also dress them up as ponies for your kids next birthday party. The children will have a blast riding around on them 🎠. Just make sure to keep 'em away from the missus…<br/>"
    },
    {
        header: "Is the project about Rock Music only?",
        content: "No! Our ❤️ for music is universal. We have various musicians in our project, the whole project is about MUSIC and not about Rock... so any genre, any musician, anyone who simply loves music as much as we do should join."
    },
    {
        header: "So are you guys the real Deal? Like actual, real life Rockstars?",
        content: "In our wildest dreams, yes. Sorry to disappoint you, dude. We actually are a bunch of music loving crypto nerds and artists. <br/>" +
            "Who were you expecting here, though? Mick Jagger? Kanye West? Dave motherfucking Grohl? 😉"
    }

]

export function Faq() {
    const [active, setActive] = useState<string>(FaqItems[0].header);

    return <div className="section-faq">
        <div className="section-header" />
        <div className="faq-container">
            {FaqItems.map(f => <div className={"faq-item " + (f.header==active?'active':'')} key={f.header}>
               <div className="faq-header" onClick={()=>{setActive(f.header)}}>
                   <div className="header-text" dangerouslySetInnerHTML={{__html:f.header}} />
                   <div className="arrow" />
               </div>
               <div className="faq-content" dangerouslySetInnerHTML={{__html:f.content}} />
            </div>)}
        </div>
    </div>;
}
