import {Header} from "./sections/Header";
import "./Page.sass";
import {About} from "./sections/About";
import {OldRoadMap} from "./sections/OldRoadMap";
import {Icons} from "./sections/Icons";
import {Faq} from "./sections/Faq";
import {Team} from "./sections/Team";
import {Discord} from "./sections/Discord";
import {Footer} from "./sections/Footer";
import {useEffect, useRef, useState} from "react";
import {Worldtour} from "./sections/Worldtour";
import {OutlookShort} from "./sections/OutlookShort";
import {Mission} from "./sections/Mission";
import {RoadMap} from "./sections/RoadMap";
import {Slider} from "./sections/Slider";
import {Metaverse} from "./sections/Metaverse";
import {Rarity} from "./sections/Rarity";
import {Bandroom} from "./sections/Bandroom";
import {SoundRarity} from "./sections/SoundRarity";
import {LegendsSlider} from "./sections/LegendsSlider";
import {HallOfFame} from "./sections/HallOfFame";
import {HowTo} from "./sections/HowTo";

export type Menu = "about"|"rarity"|"bandroom"|"mission"|"team";

export function Page() {
    const [isMobile, setIsMobile] = useState<boolean>(true);
    const [currentMenu, setCurrentMenu] = useState<Menu>("about");

    const aboutRef = useRef<HTMLDivElement>(null);
    const bandroomRef = useRef<HTMLDivElement>(null);
    const missionRef = useRef<HTMLDivElement>(null);
    const faqRef = useRef<HTMLDivElement>(null);
    const teamRef = useRef<HTMLDivElement>(null);
    const rarityRef = useRef<HTMLDivElement>(null);
    const howtoRef = useRef<HTMLDivElement>(null);

    const refs = {
        "about": aboutRef,
        "rarity": rarityRef,
        "bandroom": bandroomRef,
        "mission": missionRef,
        "faq": faqRef,
        "team": teamRef,
        "howto": howtoRef
    };

    const navigateTo = (menu: Menu) => {
        const ref = refs[menu];
        if (!ref || !ref.current) return;
        setCurrentMenu(menu);
        window.scrollTo({
            top: ref.current.offsetTop,
            left: 0,
            behavior: 'smooth'
        });
    };

    const setVh = () => {
        let vh = window.innerHeight * 0.01;
        document.documentElement.style.setProperty('--vh', `${vh}px`);
    };

    useEffect(()=>{
        if (isMobile && document.body.offsetWidth>=480) {
            setIsMobile(false);
            return;
        }
        setVh();
        window.addEventListener("resize", ()=>setVh());

        const section = window.location.search.substring(1).split('&').map(s=>s.split('=')).find(s=>s[0]=='section');
        if (section) {
            const sectionName = section[1];
            if (sectionName in refs) {
                navigateTo(sectionName as Menu);
            }
        }

    }, []);

    return <div className="sections">
        <div className="badge" />
        <div className="section">
            <Header onNavigation={menu => navigateTo(menu)}
                    activeNavigation={currentMenu}
                    isMobile={isMobile}
                    onlyMenu={false}
            />
        </div>
        <div className={"section about auto"} ref={aboutRef}>
            <About isMobile={isMobile}/>
        </div>
        <div className="section film-separator"/>
        <div className="section auto" ref={rarityRef}>
            <Rarity isMobile={isMobile}/>
        </div>
        <div className="section auto" ref={missionRef}>
            <Slider />
        </div>
        <div className="section auto">
            <Metaverse />
        </div>
        <div className="section auto" ref={bandroomRef}>
            <Bandroom />
        </div>
        <div className="section auto">
            <HallOfFame isHomepage={true}/>
        </div>
        <div className="section-auto" ref={howtoRef}>
            <HowTo/>
        </div>
        <div className="section auto">
            <Mission/>
        </div>
        <div className="section auto" ref={teamRef}>
            <Team/>
        </div>
        <div className="section auto">
            <LegendsSlider isMobile={isMobile}/>
        </div>
        <div className="section auto" ref={faqRef}>
            <Faq/>
        </div>
        <div className="section auto">
            <Footer/>
        </div>
    </div>;
}
