import "./Slider.sass";
import slider1 from "../assets/Image-Slider-01.jpg";
import slider2 from "../assets/Image-Slider-02.jpg";
import { ReactCompareSlider, ReactCompareSliderImage } from 'react-compare-slider';

export function Slider() {
    return <div className="section-slider">
        <div className="section-header-wrapper">
            <div className="section-header" />
        </div>
        <div className="slider-wrapper">
            <ReactCompareSlider
                itemOne={
                    <ReactCompareSliderImage
                        src={slider1}
                    />
                }
                itemTwo={
                    <ReactCompareSliderImage
                        src={slider2}
                    />
                }
            />
        </div>
    </div>
}
