import React, {useEffect, useRef, useState} from "react";
import "./SceneTimer.sass";

interface Time {
    days: string;
    hours: string;
    minutes: string;
    seconds: string;
}

export function SceneTimer({isMobile = true}: { isMobile: boolean }) {
    const [show, setShow] = useState<boolean>(true);
    const [time, setTime] = useState<Time>({days: "00", hours: "00", minutes: "00", seconds: "00"});
    const [isLabelShow, setLabelShow] = useState<boolean>(false);
    const counterRef = useRef<HTMLDivElement>(null);
    const p2 = (v: number) => {
        v >>= 0;
        if (v >= 10) return v.toString();
        return `0${v}`;
    };
    const updateTime = () => {
        const targetTime = new Date('2021-11-17T18:00:00+00:00');
        const currentTime = new Date();
        const secs = (targetTime.getTime() - currentTime.getTime()) / 1000 >> 0;
        if (secs <= 0) {
            setTime({days: "00", hours: "00", minutes: "00", seconds: "00"});
            setShow(false);
            return;
        }
        const day = 24*60*60;
        const days = p2(secs / day);
        const hours = p2(secs % day / 3600);
        const minutes = p2(secs % 3600 / 60);
        const seconds = p2(secs % 60);
        setTime({days, hours, minutes, seconds});
    };
    useEffect(()=>{
        updateTime();
        const to = setInterval(()=>{
            updateTime();
        }, 1000);
        setLabelShow(true);
        return () => {
            clearTimeout(to);
        }
    }, [isMobile]);
    if (!show) return null;
    return <div className="timer-wrapper">
        <div className="timer-label">Public Sale</div>
        <div className={"timer "+(isLabelShow?"show":"")} ref={counterRef}>
            {
                [
                    ["days", "Days"],
                    ["hours", "Hours"],
                    ["minutes", "Mins"],
                    ["seconds", "Secs"]
                ].map(([key, label]) => {
                    return <div className="col" key={key}>
                        <div className="value">{time[key as keyof Time]}</div>
                        <div className="label">{label}</div>
                    </div>
                })
            }
        </div>


    </div>;
}
