import "./AudioVisualArt.sass";
import {AudioVisualArtPlayer} from "./AudioVisualArtPlayer";

export function AudioVisualArt({isMobile = true}: { isMobile: boolean }) {
    return <div className="ava-container">
        <div className="text">
            <div className="text-header">9'990 NFT'S!</div>
            <p>There are 9’990 Uniquehorns rocking on the ETH blockchain, <b>45 of them are legendary</b> and inspired the whole collection with their legendary traits.<br/><br/>
               Each NFT is an <b>access pass</b> to <b>exclusive Metaverse gigs</b>, <b>member only benefits</b> and <b>exclusive collabs</b>. <br/><br/>
                You can start joining metaverse shows in our TRU band room now.<br/><br/>

            </p>
        </div>
        <div className="player-container">
            <AudioVisualArtPlayer isMobile={isMobile}/>
        </div>
    </div>
}
