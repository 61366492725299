import "./Mission.sass";

export function Mission() {
    return <div className="section-mission">
        <div className="section-header" />
        <div className="mission-section-content">
            <div className="mission-block">
                <div className="guitar" />
                <div className="text">
                    <h2>Why should you put your TRUst in us?</h2>
                    We are creative. We are nerds. We love technology. We love good design. We hate routine.
                    <br/>Basically that’s why we (UniKoЯn + Fairy Mercury) founded
                    a <a target="_blank" href="https://software-brauerei.ch">digital innovation agency</a> in
                    the heart of Europe seven years ago. <br/><br/>
                    Other NFT projects have to transform into a Tech company after the launch - we're already there!
                    <br/><br/>
                    Deep down we always wanted to be Rockstars though. And we know you feel the same way...
                    <br/>You suppressed that desire for way too long, no more excuses:
                    <br/><br/>Join our band and come #RockWithTRUs!
                </div>
            </div>
        </div>
    </div>
}
