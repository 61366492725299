import {Scene} from "./Scene";
import "./Header.sass";
import twitter from "../assets/Main_Landing_Page/Website_Assets/icon-twitter.svg";
import opensea from "../assets/Main_Landing_Page/Website_Assets/icon-opensea.svg";
import discord from "../assets/Main_Landing_Page/Website_Assets/icon-discord.svg";
import decentral from "../assets/decentraland-mana-logo-small.png";
import {Menu} from "../Page";

import {config} from "../config";
import {useEffect, useState} from "react";
import {SceneMobile} from "./SceneMobile";

export function Header({
    onNavigation = (menu: Menu)=>{},
    activeNavigation,
    isMobile = true,
    onlyMenu = false
}: {
    onNavigation: (menu: Menu)=>void,
    activeNavigation?: Menu,
    isMobile: boolean,
    onlyMenu: boolean
}) {
    const [menuOpen, setMenuOpen] = useState<boolean>(false);
    const [showMint, setShowMint] = useState<boolean>(false);

    const onNav = (nav: Menu) => {
        onNavigation(nav);
        setMenuOpen(false);
    };

    const tick = () => {
        const targetTime = new Date('2021-11-11T18:00:00+00:00');
        const currentTime = new Date();
        const secs = (targetTime.getTime() - currentTime.getTime()) / 1000 >> 0;
        if (secs < 0) {
            setShowMint(true);
        }
    };

    useEffect(()=>{
        tick();
        const intr = setInterval(()=>{
            tick();
        }, 1000);
        return () => {
            clearInterval(intr);
        };
    }, []);

    return <div className="section-head">
        <div className="header">
            <a className="logo" href="/" />
            <div className="menu-trigger" onClick={()=>setMenuOpen(v=>!v)}/>
            <div className={(isMobile?"mobile-menu":"menu")+" "+(menuOpen?"open":"")}>
                <ul>
                    {
                        ([
                            ["about", "About"],
                            ["rarity", "Rarity"],
                            ["bandroom", "Band Room"],
                            ["mission", "Mission"],
                            //["worldtour", "Worldtour"],
                            //["faq", "FAQ"],
                            ["team", "Team"],
                        ] as [Menu, string][]).map(([link, name]) =>
                            <li key={link}>
                                <a href=""
                                   className={activeNavigation == link ? "active" : ""}
                                   onClick={e=>{onNav(link);e.preventDefault()}}
                                >{name}</a>
                            </li>
                        )
                    }
                </ul>
            </div>
            <div className="icons">
                <ul>
                    <li>
                        <a href={config.openseaLink}>
                            <img src={opensea} />
                        </a>
                    </li>
                    <li>
                        <a href={config.decentrallangLink}>
                            <img src={decentral} />
                        </a>
                    </li>
                    <li>
                        <a href={config.discordLink}>
                            <img src={discord} />
                        </a>
                    </li>
                    <li>
                        <a href={config.twitterLink}>
                            <img src={twitter} />
                        </a>
                    </li>
                    {showMint&&<li>
                        <a className="button green" href="https://mint.rockinguniquehorns.com/therockinguniquehorns" target="_blank">MINT NOW <span className="icon arrow-right"/></a>
                    </li>}
                </ul>
            </div>
        </div>
        {!onlyMenu&&<>
            {!showMint&&<div className="scroll-down" onClick={()=>onNav("about")}/>}
            {showMint&&<div className="bottom-mint-button">
                <a className="button green" href="https://mint.rockinguniquehorns.com/therockinguniquehorns" target="_blank">MINT NOW <span className="icon arrow-right"/></a>
            </div>}
            {isMobile&&<SceneMobile />}
            {!isMobile&&<Scene />}
        </>}
    </div>;
}
