import "./Team.sass";

export function Team() {
    return <div className="section-team">
        <div className="team-container">
            <div className="item">
                <div className="icon">
                    <div className="social">
                        <a target="_blank" className="linkedin" href="https://www.linkedin.com/in/florian-m%C3%BCller-12606861" />
                        <a target="_blank" className="instagram" href="https://www.instagram.com/_flomueller" />
                        <a target="_blank" className="twitter" href="https://twitter.com/Fairy__Mercury" />
                    </div>
                    <video controls preload="none" poster="team/2.png">
                        <source src="/team/2.mp4" type="video/mp4" />
                    </video>
                </div>
                <div className="content">
                    <div className="header">Fairy Mercury</div>
                    <div>
                        <p className="role">Experienced Serial Entrepreneur, Lead Singer, Creative Dev Dude</p>
                        <p className="description">
                            Eternally inspired by the one time in the karaoke club
                            when not everyone straight up left the moment he started singing...
                        </p>
                        <a target="_blank" href="https://www.youtube.com/watch?v=6k7esY7g4Y8">will you do the Fandango?</a>
                    </div>
                </div>
            </div>
            <div className="item">
                <div className="icon">
                    <div className="social">
                        <a target="_blank" className="linkedin" href="https://www.linkedin.com/in/domenic-benz/" />
                        <a target="_blank" className="instagram" href="https://www.instagram.com/domenic.benz/" />
                        <a target="_blank" className="twitter" href="https://twitter.com/TRU_UniKorn" />
                    </div>
                    <video controls  preload="none" poster="team/1.png">
                        <source src="/team/1.mp4" type="video/mp4" />
                    </video>
                </div>
                <div className="content">
                    <div className="header">UniKoЯn</div>
                    <div>
                        <p className="role">Experienced Serial Entrepreneur, Wannabe Drummer, Creative Dev Dude</p>
                        <p className="description">
                            Oh, and also:<br/>
                            Boom na da noom na na nema
                            <br/>Da boom na da noom na namena
                        </p>
                        <a target="_blank" href="https://www.youtube.com/watch?v=jRGrNDV2mKc">Go!</a>
                    </div>
                </div>
            </div>
            <div className="item">
                <div className="icon">
                    <div className="social">
                        <a target="_blank" className="linkedin" href="https://www.linkedin.com/in/shelleyvan"/>
                        <a target="_blank" className="twitter" href="https://twitter.com/teenybod"/>
                        <a target="_blank" className="web" href="https://songbirdcoagency.com"/>
                    </div>
                    <video controls  preload="none" poster="team/tru-73.png">
                        <source src="/team/tru-73.mp4" type="video/mp4" />
                    </video>
                </div>
                <div className="content">
                    <div className="header">ShelleyVan aka THEShelley</div>
                    <div>
                        <p className="role">Tour Manager</p>
                        <p className="description">
                            Keeping things running smooth as hell
                            behind the scenes.
                        </p>
                        <a target="_blank" href="https://www.youtube.com/watch?v=8y7105u0-94">LFG!</a>
                    </div>
                </div>
            </div>
            <div className="item">
                <div className="icon">
                    <video controls preload="none" poster="team/4.png">
                        <source src="/team/4.mp4" type="video/mp4" />
                    </video>
                </div>
                <div className="content">
                    <div className="header">Corny Horn/Horny Corn</div>
                    <div>
                        <p className="role">Bassist, Artist</p>
                        <p className="description">
                            Where are my groupies?<br/>
                            Wanna have some fun? Me too!
                        </p>
                        <a target="_blank" href="https://www.youtube.com/watch?v=-j6muYHRX90">Have fun</a>
                    </div>
                </div>
            </div>
            <div className="item">
                <div className="icon">
                    <div className="social">
                        <a target="_blank" className="spotify" href="https://open.spotify.com/artist/7MireV1xKCnJ3bQ1UEkH27?si=UhURDdWXSIuxyoPxo1BNAA"/>
                    </div>
                    <video controls preload="none" poster="team/5.png">
                        <source src="/team/5.mp4" type="video/mp4" />
                    </video>
                </div>
                <div className="content">
                    <div className="header">Soundicorn</div>
                    <div>
                        <p className="role">Artist & Musician</p>
                        <p className="description">
                            I can give a voice to your Uniquehorns!<br/>
                            Soundcheck: Test, test, test... 1.. 2.. 3...
                        </p>
                    </div>
                </div>
            </div>
            <div className="item">
                <div className="icon">
                    <video controls preload="none" poster="team/6.png">
                        <source src="/team/6.mp4" type="video/mp4" />
                    </video>
                </div>
                <div className="content">
                    <div className="header">"Fuck Sake" Steve</div>
                    <div>
                        <p className="role">Lead Guitarist, currently MIA</p>
                        <p className="description">
                            Current whereabouts: unknown.<br/>
                            Help us find Steve over in our  <a target="_blank" href="https://discord.gg/HVeaMfcJxM">discord!</a>
                        </p>
                        <a target="_blank" href="https://www.youtube.com/watch?v=aXqkNF8fgs8&t=23s">Steve! Steve!</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
}
