import "./AudioVisualArtPlayer.sass";
import {useEffect, useRef, useState} from "react";

import vocal_line_icon from "./../assets/player/vocal_line_icon.png";
import drum_line_icon from "./../assets/player/drum_line_icon.png";
import drums_direct from "./../assets/player/drums_direct.png";
import guitar_direct from "./../assets/player/guitar_direct.png";
import guitar_line_icon from "./../assets/player/guitar_line_icon.png";
import vocal_direct from "./../assets/player/vocal_direct.png";

type Coords = [number, number];

interface Button {
    coords: Coords;
    image: string;
    audio: string;
}

const audio_all = "/audio/all.wav";
const audio_drums = "/audio/drums.wav";
const audio_guitar = "/audio/guitar.wav";
const audio_vocal = "/audio/vocal.wav";

const audios = [
    audio_all,
    audio_drums,
    audio_guitar,
    audio_vocal
];

function preloadAudios() {
    for (const audio of audios) {
        new Audio(audio);
    }
}

export function AudioVisualArtPlayer({isMobile = true}: { isMobile: boolean }) {
    const buttonSize = isMobile?8:6;

    const [containerDims, setContainerDims] = useState<[number, number]|null>(null);

    const containerRef = useRef<HTMLDivElement>(null);
    const backgroundWidth = 4544,
        backgroundHeight = 3237,
        playButtonCoords: Coords = [673, 513],
        pauseButtonCoords: Coords = [980, 513];

    useEffect(() => {
        preloadAudios();
        const onResize = () => {
            if (!containerRef.current) return;
            const container = containerRef.current;
            const dims: Coords = [
                container.offsetWidth,
                container.offsetWidth/(backgroundWidth/backgroundHeight)
            ];
            setContainerDims(dims);
        };
        onResize();
        document.addEventListener("resize", onResize);
        return () => {
            document.removeEventListener("resize", onResize);
        };
    }, []);

    const buttons: Button[] = [
        {
            coords: [2357, 833],
            image: guitar_line_icon,
            audio: audio_guitar
        },
        {
            coords: [2357, 1157],
            image: vocal_line_icon,
            audio: audio_vocal
        },
        {
            coords: [2357, 1481],
            image: drum_line_icon,
            audio: audio_drums
        },
        {
            coords: [1065, 2061],
            image: drums_direct,
            audio: audio_drums
        },
        {
            coords: [2269, 2625],
            image: vocal_direct,
            audio: audio_vocal
        },
        {
            coords: [3821, 2132],
            image: guitar_direct,
            audio: audio_guitar
        },
    ];

    const buttonStyle = ([x, y]: [number, number], m = 1) => {
        if (!containerDims) return {};
        const [containerWidth, containerHeight] = containerDims;
        const buttonWidth = containerWidth*buttonSize/100*m;
        const xC = containerWidth*x/backgroundWidth - buttonWidth/2,
            yC = containerHeight*y/backgroundHeight - buttonWidth/2;
        return {
            left: xC+'px',
            top: yC+'px',
            width: buttonWidth+'px',
            height: buttonWidth+'px'
        };
    };

    const onPlayButtonClick = () => {
        new Audio(audio_all).play();
    };

    const onPauseButtonClick = () => {

    };

    const containerStyle = containerDims?{
        width: containerDims[0]+'px',
        height: containerDims[1]+'px',
    }:{};

    const play = (button: Button) => {
        return () => {
            const url = button.audio;
            new Audio(url).play();
        };
    };

    return <div className="audio-visual-art-player" ref={containerRef}>
        <div className="audio-player-container"
             style={containerStyle}
        >
            <div className="button play-button glow"
                 style={buttonStyle(playButtonCoords, 1.5)}
                 onClick={onPlayButtonClick}
            />
            {/*<div className="button pause-button"
                 style={buttonStyle(pauseButtonCoords)}
                 onClick={onPauseButtonClick}
            />*/}
            {buttons.map((button, i)=>{
                const style = {
                    backgroundImage: `url(${button.image})`,
                    ...buttonStyle(button.coords)
                };

                return <div className="button action-button glow" key={i}
                            style={style}
                            onClick={play(button)}
                            />
            })}
        </div>
    </div>;
}
