import {IteractionEvent, Point, Scene} from "./Scene";


export class SceneMobile extends Scene {
    imgOffset = .5;

    prevPoint?: Point;
    prevPointDate?: Date;

    aDeg = 90;
    dangling = true;

    isMobile = true;

    protected addEventListeners() {
        const el = this.canvas!;
        el.addEventListener('mousemove', this.onMouseMove);
        el.addEventListener('touchmove', this.onMouseMove);
    }

    onMouseMove(event: IteractionEvent) {
        const [w, h] = this.getWH();
        const point = this.getEventPoint(event);
        if (!point) return;
        //this.dangling = false;
        
        if (this.prevPoint && this.prevPointDate && (new Date().getTime()-this.prevPointDate.getTime())<100) {
            const dist = (point.x - this.prevPoint.x)/w * w/this.bgWidth * -1;
            this.imgOffset += dist;
            this.imgOffset = Math.max(0, this.imgOffset);
            this.imgOffset = Math.min(this.imgOffset, 1-w/(h*this.bgDim));
        }
        this.prevPoint = point;
        this.prevPointDate = new Date();
    }

    protected danglingCycle() {
        const c = (Math.sin(Date.now()/1000)+1)/2;
        this.aDeg = 85 + c*10;

        if (!this.dangling) {
            if (Math.abs(this.aDeg-90)<2) {
                this.aDeg = 90;
                return;
            }
        }
        setTimeout(()=>this.danglingCycle(), 1000/30);
    }

    protected getStartPoint(): Point {
        const [w, h] = this.getWH();
        return {x: .5 * w, y: 0};
    }

    protected getAngleCircleRadius() {
        const [w, h] = this.getWH();
        return h*1.2;
    }

    protected drawBgImage(ctx: CanvasRenderingContext2D) {
        const [w, h] = this.getWH(),
            imgW = h*this.bgDim;

        ctx.drawImage(this.bg!,
            this.imgOffset*this.bgWidth,
            0,
            this.bgWidth*(1-this.imgOffset),
            this.bgHeight,
            0,
            0,
            imgW*(1-this.imgOffset),
            h
        );
    }
}
