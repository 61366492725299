import "./Scene.sass";
import React, {useEffect, useRef} from "react";
import bg from "../assets/background_new.jpeg";
import {SceneTimer} from "./SceneTimer";

export interface Point {
    x: number;
    y: number;
}

export type IteractionEvent = MouseEvent | TouchEvent;

export function isTouchEvent(event: IteractionEvent): event is TouchEvent {
    return Boolean((event as any).touches);
}

export class Scene extends React.Component<any, any> {
    canvasBgRef: React.RefObject<HTMLCanvasElement>;
    canvasBg?: HTMLCanvasElement;
    ctxBg?: CanvasRenderingContext2D;

    canvasRef: React.RefObject<HTMLCanvasElement>;
    canvas?: HTMLCanvasElement;
    ctx?: CanvasRenderingContext2D;

    bg?: HTMLImageElement;
    bgHeight = 0;
    bgWidth = 0;
    bgDim = 1;

    aDeg = 155;

    loadTime?: Date;
    dangling = false;

    isMobile = false;

    constructor(props: any) {
        super(props);
        this.canvasBgRef = React.createRef();
        this.canvasRef = React.createRef();
        this.onMouseMove = this.onMouseMove.bind(this);
    }

    componentDidMount() {
        if (!this.canvasBgRef?.current || !this.canvasRef?.current) return;
        this.canvasBg = this.canvasBgRef?.current || undefined;
        this.canvas = this.canvasRef?.current || undefined
        if (!this.canvasBg || !this.canvas) return;
        this.ctxBg = this.canvasBg.getContext("2d") || undefined;
        this.ctx = this.canvas.getContext("2d") || undefined;
        if (!this.ctxBg || !this.ctx) return;
        this.loadTime = new Date();
        this.addEventListeners();
        this.setCanvasDims();
        this.init();
    }

    protected addEventListeners() {
        window.addEventListener('mousemove', this.onMouseMove);
        window.addEventListener('touchmove', this.onMouseMove);
    }

    onMouseMove(event: IteractionEvent) {
        const point = this.getEventPoint(event),
            center = this.getStartPoint();
        if (!point) return;

        const a = {
            x: point.x - center.x,
            y: point.y - center.y,
        };
        this.aDeg = Math.atan2(a.y, a.x) * 180 / Math.PI;
        this.dangling = false;
    }

    getEventPoint(event: IteractionEvent): Point | null {
        if (isTouchEvent(event)) {
            if (event.touches.length == 0) return null;
            return {x: event.touches[0].pageX, y: event.touches[0].pageY};
        } else {
            return {x: event.pageX, y: event.pageY};
        }
    }

    private init() {
        const img = new Image();
        img.src = bg;
        img.onload = () => {
            this.bg = img;
            this.bgWidth = img.width;
            this.bgHeight = img.height;
            this.bgDim = this.bgWidth/this.bgHeight;
            this.start();
            this.loadTime = new Date();
        }
    }

    private start() {
        this.danglingCycle();
        this.drawCycle();
    }

    protected danglingCycle() {
        if (!this.dangling) return;
        const c = (Math.sin(Date.now()/1000)+1)/2;
        this.aDeg = 40 + c*100;
        setTimeout(()=>this.danglingCycle(), 1000/30);
    }

    private drawCycle() {
        this.draw();
        requestAnimationFrame(() => this.drawCycle());
    }

    private draw() {
        const ctxBg = this.ctxBg!,
            ctx = this.ctx!;
        const [w, h] = this.getWH();

        ctx.clearRect(0, 0, w, h);
        ctxBg.clearRect(0, 0, w, h);

        // background -> ctxBackground
        ctxBg.fillStyle = 'rgb(0,0,0,.9)';
        ctxBg.fillRect(0, 0, w, h);
        this.drawBgImage(ctxBg);

        ctx.save();

        // light
        this.drawLightBackground(this.ctx!);
        this.drawLightTop(this.ctx!);

        // bg in light
        ctx.globalCompositeOperation = 'source-atop';
        ctx.fillStyle = 'rgb(0,0,0,.9)';
        ctx.fillRect(0, 0, w, h);
        this.drawBgImage(ctx);

        // bg behind the light
        ctx.globalCompositeOperation = 'destination-over';

        ctx.fillStyle = 'rgb(0,0,0,.75)';
        ctx.fillRect(0, 0, w, h);

        ctx.restore();
    }

    protected drawBgImage(ctx: CanvasRenderingContext2D) {
        const [w, h] = this.getWH();
        ctx.drawImage(this.bg!, -1*(h*this.bgDim-w)/2, 0, h*this.bgDim, h);
    }

    private drawLightBackground(ctx: CanvasRenderingContext2D) {
        ctx.save();
        const [w, h] = this.getWH(),
            aDeg = this.aDeg,
            aRad = aDeg * Math.PI / 180;
        const sP = this.getStartPoint(),
            cP1 = this.getAnglePoint(aDeg-0.01),
            cP2 = this.getAnglePoint(aDeg+0.01),
            lP = this.getAnglePoint(aDeg + 15),
            rP = this.getAnglePoint(aDeg - 15);

        const d = Math.sqrt((lP.x - rP.x) ** 2 + (lP.y - rP.y) ** 2) / 8;

        let gradient1 = ctx.createLinearGradient(sP.x, sP.y, sP.x+Math.cos(aRad+Math.PI/12-Math.PI/2)*d, sP.y+Math.sin(aRad+Math.PI/12-Math.PI/2)*d);
        gradient1.addColorStop(0, 'rgba(255,255,255,.01)');
        gradient1.addColorStop(.3, 'rgba(255,255,255,.5)');
        gradient1.addColorStop(1, 'rgba(255,255,255,.9)');

        let gradient2 = ctx.createLinearGradient(sP.x, sP.y, sP.x-Math.cos(aRad-Math.PI/12-Math.PI/2)*d, sP.y-Math.sin(aRad-Math.PI/12-Math.PI/2)*d);
        gradient2.addColorStop(0, 'rgba(255,255,255,.01)');
        gradient2.addColorStop(.3, 'rgba(255,255,255,.5)');
        gradient2.addColorStop(1, 'rgba(255,255,255,.9)');

        ctx.globalCompositeOperation = 'lighten';
        ctx.beginPath();
        ctx.moveTo(sP.x, sP.y);
        ctx.lineTo(lP.x, lP.y);
        ctx.lineTo(cP1.x, cP1.y);
        ctx.lineTo(sP.x, sP.y);
        ctx.closePath();
        ctx.fillStyle = gradient1;
        ctx.fill();

        ctx.beginPath();
        ctx.moveTo(sP.x, sP.y);
        ctx.lineTo(cP2.x, cP2.y);
        ctx.lineTo(rP.x, rP.y);
        ctx.lineTo(sP.x, sP.y);
        ctx.closePath();
        ctx.fillStyle = gradient2;
        ctx.fill();

        ctx.restore();
    }

    private drawLightTop(ctx: CanvasRenderingContext2D) {
        ctx.save();
        const [w, h] = this.getWH(),
            aDeg = this.aDeg,
            aRad = aDeg * Math.PI / 180;
        const sP = this.getStartPoint(),
            lP = this.getAnglePoint(aDeg + 8),
            rP = this.getAnglePoint(aDeg - 8);

        ctx.beginPath();
        ctx.moveTo(sP.x, sP.y);
        ctx.lineTo(lP.x, lP.y);
        ctx.lineTo(rP.x, rP.y);
        ctx.lineTo(sP.x, sP.y);
        ctx.closePath();
        ctx.fillStyle = `rgba(255,255,255,0.21)`;
        ctx.fill();

        ctx.restore();
    }

    protected getStartPoint(): Point {
        const [w, h] = this.getWH();
        return {x: 1300 / 1920 * w, y: 0 / 1080 * (w / this.bgDim)};
    }

    private getAnglePoint(aDeg: number): Point {
        const [w, h] = this.getWH();
        const r = this.getAngleCircleRadius();

        const aRad = aDeg * Math.PI / 180,
            dY = Math.sin(aRad) * r,
            dX = Math.cos(aRad) * r;
        const {x, y} = this.getStartPoint();
        return {
            x: x + dX,
            y: y + dY
        }
    }

    protected getAngleCircleRadius() {
        const [w, h] = this.getWH();
        return w;
    }

    private setCanvasDims() {
        const [w, h] = this.getWH();

        this.canvas!.width = w;
        this.canvas!.height = h;
        this.canvasBg!.width = w;
        this.canvasBg!.height = h;
    }

    protected getWH(): [number, number] {
        return [window.innerWidth, window.innerHeight];
    }

    render() {
        return <div className="scene">
            <canvas ref={this.canvasBgRef} style={{zIndex: 1}}/>
            <canvas ref={this.canvasRef} style={{zIndex: 2}}/>
            <SceneTimer isMobile={this.isMobile}/>
        </div>
    }
}

